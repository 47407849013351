import React, { createContext, useState, useEffect, useContext } from "react";

const lightTheme = {
  primaryColor: "blue",
  secondaryColor: "gray",
  backgroundColor: "white",
  barColor: "#0b0b8d",
  // other light theme properties...
};

const darkTheme = {
  primaryColor: "white",
  secondaryColor: "black",
  backgroundColor: "darkgrey",
  barColor: "#0b0b8d",
  // other dark theme properties...
};

const ThemeContext = createContext();

const ThemeProvider = ({ children }) => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    loadDarkModePreference();
  }, []);

  const loadDarkModePreference = async () => {
    try {
      const preference = localStorage.getItem("darkMode");
      setIsDarkMode(preference === "true");
    } catch (error) {
      console.error("Error loading dark mode preference:", error);
    }
  };

  const toggleDarkMode = async () => {
    const newDarkModeValue = !isDarkMode;
    setIsDarkMode(newDarkModeValue);
    try {
      await localStorage.setItem("darkMode", newDarkModeValue.toString());
    } catch (error) {
      console.error("Error saving dark mode preference:", error);
    }
  };

  const theme = isDarkMode ? darkTheme : lightTheme;

  return (
    <ThemeContext.Provider value={{ theme, isDarkMode, toggleDarkMode }}>
      {children}
    </ThemeContext.Provider>
  );
};

const useTheme = () => {
  return useContext(ThemeContext);
};

export { ThemeProvider, useTheme };
