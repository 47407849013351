import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";

const TRACKING_ID = "G-6XZJ9L1TKP"; // Replace with your GA4 tracking ID
ReactGA.initialize(TRACKING_ID);

const GoogleAnalytics = () => {
  const location = useLocation();

  useEffect(() => {
    // Send a pageview whenever the route changes
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
    });
  }, [location]);

  return null;
};

export default GoogleAnalytics;
