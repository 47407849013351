import { fetchData } from "./CustomFetch";

export const endpoint = process.env.REACT_APP_ENDPOINT + "/server/api";
export const home = process.env.REACT_APP_ENDPOINT;
export const title = "CAPIFY NIGERIA";

export const token = "SSCy-EDIf-7Xrd-lKjP-gYr9-Ijvc-rDZo-u5X2"; // Bearer token
export const defaultSingleBackgroundColor = "#BCBCBC";
export const darkBlue = "#1e4473";
export const buttonbg = "#4a4cc7";
export const buttonbgRGBA = "rgba(74, 76, 199, 0.7)";
export const buttonTextColor = "#ffffff";
export const defaultTextColor = "#000000";
export const freetier = 5;
export const blueColor = "#1f21bf";
export const properties = {
  PHONE: 2348035830617,
  NAIRA_SYMBOL: "₦",
  DISCLAIMER:
    "The business ideas generated by this AI tool are intended for informational purposes only. While we strive to provide accurate and relevant suggestions, it's important to recognize that success in business depends on various factors beyond initial capital and location, including market conditions, industry expertise, and individual circumstances. Therefore, users are encouraged to conduct thorough research, seek professional advice, and exercise their own judgment before pursuing any business venture. By using this AI tool, you acknowledge that the suggestions provided do not guarantee success or profitability, and you agree to hold harmless the developers and providers of this service from any liabilities or losses incurred as a result of implementing the suggested ideas.",
};

export const numberFormat = (number, decimal = 0) => {
  return new Intl.NumberFormat("en-US", {
    // style: "currency",
    // currency: "NGN",
    minimumFractionDigits: decimal,
  }).format(number);
};

export const fetchCreditBalance = async (uid) => {
  const params = { uid: uid };
  const data = await fetchData(endpoint + "/userinfo/", "POST", params);
  const status = data?.status;

  if (status) {
    return data.message[0].credits;
  }
};

export const updateURLBar = (params) => {
  window.history.pushState(
    {},
    "",
    `${window.location.pathname}?${params.toString()}`
  );
};

export const generateHash = (separator = "") => {
  const getRandomChar = () => {
    const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    return chars.charAt(Math.floor(Math.random() * chars.length));
  };

  const getRandomSegment = (length) => {
    let segment = "";
    for (let i = 0; i < length; i++) {
      segment += getRandomChar();
    }
    return segment;
  };

  return (
    getRandomSegment(4) +
    separator +
    getRandomSegment(4) +
    separator +
    getRandomSegment(4) +
    separator +
    getRandomSegment(6) +
    separator +
    getRandomSegment(4) +
    separator +
    getRandomSegment(4)
  );
};

export // Utility function to get the start date of the week given a year and week number
function getStartDateOfWeek(weekYear) {
  // Extract year and week from the weekYear string
  const [year, week] = weekYear?.split("-").map(Number) ?? [0, 0];

  // Check for valid year and week
  if (isNaN(year) || isNaN(week)) {
    throw new Error("Invalid week-year format");
  }

  // Calculate the start date of the week
  const firstDayOfYear = new Date(year, 0, 1); // January 1st of the year
  const firstWeekStart =
    firstDayOfYear.getDate() -
    firstDayOfYear.getDay() +
    (firstDayOfYear.getDay() === 0 ? -6 : 1); // Adjust to Monday
  const startDate = new Date(year, 0, firstWeekStart + (week - 1) * 7);

  return startDate;
}
