import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";

// Initialize the Font Awesome library outside of the component
library.add(fas);

function Icon(props) {
  return <FontAwesomeIcon {...props} />;
}

export default Icon;
