import React, { useEffect, useState } from "react";

function useColorScheme() {
  const [colorScheme, setColorScheme] = useState(() => {
    if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
      return "dark";
    } else if (window.matchMedia("(prefers-color-scheme: light)").matches) {
      return "light";
    } else {
      return "no-preference";
    }
  });

  useEffect(() => {
    const darkModeQuery = window.matchMedia("(prefers-color-scheme: dark)");
    const lightModeQuery = window.matchMedia("(prefers-color-scheme: light)");

    const updateColorScheme = () => {
      if (darkModeQuery.matches) {
        setColorScheme("dark");
      } else if (lightModeQuery.matches) {
        setColorScheme("light");
      } else {
        setColorScheme("no-preference");
      }
    };

    darkModeQuery.addEventListener("change", updateColorScheme);
    lightModeQuery.addEventListener("change", updateColorScheme);

    return () => {
      darkModeQuery.removeEventListener("change", updateColorScheme);
      lightModeQuery.removeEventListener("change", updateColorScheme);
    };
  }, []);

  return colorScheme;
}
export default useColorScheme;
