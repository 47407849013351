// components/LoadingBar.js
import React, { useRef, useEffect } from "react";
import LoadingBar from "react-top-loading-bar";
import { useLocation } from "react-router-dom";
import { buttonbg } from "../Constants";

const ProgressBar = () => {
  const ref = useRef(null);
  const location = useLocation();

  useEffect(() => {
    if (ref.current) {
      ref.current.staticStart();
      setTimeout(() => {
        if (ref.current) {
          ref.current.complete();
        }
      }, 1000); // Adjust the delay as needed
    }
  }, [location]);

  return <LoadingBar color={buttonbg} height={5} ref={ref} />; // Set desired height
};

export default ProgressBar;
