export async function fetchData(endpoint, method = "POST", postData) {
  try {
    const apiUrl = endpoint;
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 45000); // Timeout after 30 seconds
    // Prepare fetch options
    const options = {
      method: method,
      signal: controller.signal,
    };
    // If it's a POST request, include the data
    if (method === "POST") {
      options.headers = {
        "Content-Type": "application/json",
      };
      options.body = JSON.stringify(postData);
    }
    const response = await fetch(apiUrl, options);
    clearTimeout(timeoutId); // Clear timeout if request succeeds within 30 seconds

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const res = await response.json();

    if (!res) {
      throw new Error("Invalid response from server");
    }
    return res;
  } catch (error) {
    if (error.name === "AbortError") {
      console.error("Request timed out");
      // Handle timeout error
      alert("Request timed out.");
    } else {
      // Handle other errors
      alert(error.message);
    }
  }
}
