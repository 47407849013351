import React, { useEffect, useState } from "react";
import useColorScheme from "../BrowserColorPreference";
import { useTheme } from "../ThemeContext";
import { Box, Modal } from "@mui/material";
import Icon from "../fontAwesome";

function ThemeModal() {
  const [themeModal, setThemeModal] = useState(false);
  const colorScheme = useColorScheme();
  const { isDarkMode, toggleDarkMode } = useTheme();

  useEffect(() => {
    if (colorScheme === "dark" && !isDarkMode) {
      // Function to check if disclaimer has been accepted
      const checkThemeModalShown = async () => {
        const themeModalShown = localStorage.getItem("themeModalShown");
        if (!themeModalShown) {
          // If themeModalShown is null (never shown before), show the modal
          setThemeModal(true);
          localStorage.setItem("themeModalShown", "1"); // Set themeModalShown to '1' indicating modal has been shown
        } else {
          const themeModalShownCount = parseInt(themeModalShown, 10);
          const newCount = themeModalShownCount + 1;
          localStorage.setItem("themeModalShown", newCount.toString()); // Update the count in AsyncStorage

          if (newCount % 40 === 0) {
            // Show the modal every 30th time
            setThemeModal(true);
          }
        }
      };

      checkThemeModalShown();
    }
  }, [colorScheme, isDarkMode]);

  const closeThemeModal = () => {
    localStorage.setItem("themeModalShown", "1"); // Set themeModalShown to '1' indicating modal has been shown
    setThemeModal(false);
  };

  return (
    <div>
      {themeModal && (
        <Modal
          open={themeModal}
          onClose={() => closeThemeModal()}
          className="flex justify-center items-center backdrop-blur-sm border-none outline-none"
        >
          <Box
            className={`${
              isDarkMode ? "bg-[#333]" : "bg-white"
            }  w-10/12 lg:w-5/12 p-4 rounded-xl shadow-2xl shadow-black outline-none border-none relative`}
          >
            <div
              className={`rounded-full w-16 h-16 flex justify-center items-center absolute -top-6 ${isDarkMode ? "bg-[#444] hover:bg-[#555]" : "bg-gray-200 hover:bg-gray-300"} -right-6 shadow-xl cursor-pointer`}
              onClick={() => closeThemeModal()}
            >
              <Icon icon="xmark" />
            </div>
            <h3 className="text-lg font-bold">Switch to dark mode</h3>
            <p className="mt-2">
              We observe your system color preference is in{" "}
              <strong>dark mode</strong>, would you like to use CAPIFY in dark
              mode?
            </p>

            <div className="flex mt-4 justify-center items-center flex-col">
              <button
                className="bg-custom-blue rounded-md text-white cursor-pointer px-5 py-2"
                onClick={() => toggleDarkMode()}
              >
                <Icon icon="moon" /> Switch
              </button>
            </div>
          </Box>
        </Modal>
      )}
    </div>
  );
}

export default ThemeModal;
