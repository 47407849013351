import React, { useEffect, useState } from "react";
import useColorScheme from "../BrowserColorPreference";
import { useTheme } from "../ThemeContext";
import { Box, Modal, Switch } from "@mui/material";
import Icon from "../fontAwesome";
import { Link } from "react-router-dom";

function ShowSocialMediaModal() {
  const [socialModal, setSocialModal] = useState(false);
  const [doNotShowAgain, setDoNotShowAgain] = useState(false);
  const { isDarkMode, toggleDarkMode } = useTheme();
  const storageKey = "socialmodalshown";

  useEffect(() => {
    const donotshow = localStorage.getItem(storageKey);

    setTimeout(() => {
      if (!donotshow) {
        setSocialModal(true);
      }
    }, 60000);
  }, []);

  function closeSocialModal() {
    setSocialModal(false);
    if (doNotShowAgain) {
      localStorage.setItem(storageKey, "true");
    }
  }

  return (
    <div>
      {socialModal && (
        <Modal
          open={socialModal}
          onClose={() => closeSocialModal()}
          className="flex justify-center items-center backdrop-blur-sm border-none outline-none"
        >
          <Box
            className={`${
              isDarkMode ? "bg-[#333]" : "bg-white"
            }  w-10/12 lg:w-5/12 p-4 rounded-xl shadow-2xl shadow-black outline-none border-none relative`}
          >
            <div
              className={`rounded-full w-16 h-16 flex justify-center items-center absolute -top-6 ${isDarkMode ? "bg-[#444] hover:bg-[#555]" : "bg-gray-200 hover:bg-gray-300"} -right-6 shadow-xl cursor-pointer`}
              onClick={() => closeSocialModal()}
            >
              <Icon icon="xmark" />
            </div>

            <h3 className="text-lg font-bold">Join our Community</h3>
            <p
              className={`text-sm ${isDarkMode ? "text-gray-300" : "text-gray-600"}`}
            >
              Stay informed with exclusive updates and join our growing
              community of entrepreneurs. Follow us on Instagram for the latest
              business insights, innovative ideas, and expert guidance to help
              you stay ahead in the ever-evolving business landscape.
            </p>

            <div className="flex mt-4 justify-center items-center flex-col">
              <Link
                to="https://www.instagram.com/capify.ng/"
                target="_blank"
                rel="noopener noreferrer"
                className={`justify-center items-center gap-2 border border-[#8134af]`}
                style={{
                  padding: "10px 20px",
                  borderRadius: "5px",
                  textDecoration: "none",
                  fontSize: "16px",
                  fontWeight: "bold",
                  display: "flex",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                  width={24}
                  className="fill-current"
                >
                  <defs>
                    <linearGradient
                      id="instagramGradient"
                      x1="0%"
                      y1="0%"
                      x2="100%"
                      y2="100%"
                    >
                      <stop offset="0%" stopColor="#f58529" />
                      <stop offset="30%" stopColor="#feda77" />
                      <stop offset="60%" stopColor="#dd2a7b" />
                      <stop offset="100%" stopColor="#8134af" />
                    </linearGradient>
                  </defs>
                  <path
                    fill="url(#instagramGradient)"
                    d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                  />
                </svg>
                Follow us on Instagram
              </Link>
            </div>

            <div className={`flex justify-center items-center mt-5`}>
              <Switch
                checked={doNotShowAgain}
                onChange={(event) => {
                  setDoNotShowAgain(event.target.checked);
                }}
                color="primary"
              />{" "}
              Do not show this again
            </div>
          </Box>
        </Modal>
      )}
    </div>
  );
}

export default ShowSocialMediaModal;
