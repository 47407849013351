import React from "react";

export function Container({ children, ...props }) {
  return (
    <div
      className={`${props.fullscreen ? "w-full max-w-full" : "max-w-[1300px]"} mx-auto px-3 lg:px-4`}
      {...props}
    >
      {children}
    </div>
  );
}
