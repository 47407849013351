import React, { useEffect, useState } from "react";
import { Container } from "./Custom";
import Icon from "../fontAwesome";
import { useTheme } from "../ThemeContext";
import { Outlet, Link, useNavigate, useLocation } from "react-router-dom";
import { darkBlue } from "../Constants";
import { useAuth } from "../AuthContexts";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import useColorScheme from "../BrowserColorPreference";
import logoDark from "../../images/logo-dark.png";
import logoLight from "../../images/logo-light.png";
import { Modal } from "@mui/material";

const Navbar = ({ noFooter = false, noOutlet = false }) => {
  const [isVisible, setInvisible] = useState(false);
  const [isNavFixed, setIsNavFixed] = useState(false);
  const { isDarkMode, toggleDarkMode } = useTheme();
  const colorScheme = useColorScheme();
  const { userInfo = "", logout, userLoggedIn } = useAuth() || {};
  const navigate = useNavigate();
  const location = useLocation();
  const [themeModal, setThemeModal] = useState(false);

  useEffect(() => {
    if (isDarkMode) {
      document.body.className = "dark";
    } else {
      document.body.className = "light";
    }
  }, [isDarkMode]);

  const toggleNav = () => {
    setInvisible(!isVisible);
  };

  const menuItems = [
    { text: "Home", url: "/" },
    {
      text: "Pricing",
      url: "/plans/",
    },
    {
      text: "Apps",
      submenu: [
        { text: "Capify AI", url: "/blog/capify" },
        { text: "Voice AI", url: "/blog/voice-ai" },
      ],
    },
    { text: "Blog", url: "/blog/" },
    { text: "Support", url: "/contact" },
  ];

  const handleLogout = () => {
    logout();
    navigate("/account/login/?logout=true");
  };

  React.useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setIsNavFixed(true);
        document.querySelector("nav").classList.add("shadow-lg");
      } else {
        setIsNavFixed(false);
        document.querySelector("nav").classList.remove("shadow-lg");
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuOpen = Boolean(anchorEl);
  const openProfileMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <nav
        className={`top-0 ${isNavFixed ? "sticky" : "relative"} ${isDarkMode ? "bg-gray-900" : "bg-white"} bg-opacity-60`}
        style={{ zIndex: 100000 }}
      >
        <Container
          fullscreen={
            location.pathname === "/account/dashboard/generate/advisor/"
              ? true
              : false
          }
        >
          <div className="nav-wrapper">
            <div className="nav-button-wrapper">
              {userInfo ? (
                <React.Fragment>
                  <IconButton
                    onClick={openProfileMenu}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={menuOpen ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={menuOpen ? "true" : undefined}
                  >
                    <Avatar
                      sx={{
                        backgroundColor: isDarkMode ? "#555" : "#ddd",
                        color: isDarkMode ? "#fff" : "#000",
                        width: 35,
                        height: 35,
                      }}
                      className="-mt-1 lg:-mt-1"
                    >
                      {userInfo.fullname[0]}
                    </Avatar>
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={menuOpen}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        backgroundColor: isDarkMode ? "#222" : "#fff",
                        color: isDarkMode ? "#fff" : "#000",

                        mt: 1.5,
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        "&::before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: isDarkMode ? "222" : "white",

                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  >
                    <MenuItem
                      onClick={() => {
                        navigate("/account/dashboard/");
                        handleClose();
                      }}
                    >
                      <Icon
                        icon="table-columns"
                        className={`${isDarkMode ? "text-white" : "text-black"} mr-2`}
                      />{" "}
                      Dashboard
                    </MenuItem>{" "}
                    <MenuItem
                      onClick={() => {
                        navigate("/account/profile/");
                        handleClose();
                      }}
                    >
                      <Icon
                        icon="user"
                        className={`${isDarkMode ? "text-white" : "text-black"} mr-2`}
                      />{" "}
                      Profile
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        navigate("/account/me/");
                        handleClose();
                      }}
                    >
                      {" "}
                      <Icon
                        icon="gear"
                        className={`${isDarkMode ? "text-white" : "text-black"} mr-2`}
                      />{" "}
                      My account
                    </MenuItem>
                    <Divider />
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        handleLogout();
                      }}
                    >
                      <Icon
                        icon="power-off"
                        className={`${isDarkMode ? "text-white" : "text-black"} mr-2`}
                      />{" "}
                      Logout
                    </MenuItem>
                  </Menu>
                </React.Fragment>
              ) : (
                <button
                  onClick={() => navigate("/account/login/")}
                  className="gradient-button shadow-md bg-gradient-to-b from-blue-400 to-custom-blue px-5 lg:px-10 py-1 hover:opacity-80 text-white rounded-md transition duration-500 ease-in-out transform active:scale-110"
                >
                  <Icon icon={"user"} className="block lg:hidden" />{" "}
                  <span className="hidden lg:block">Login</span>
                </button>
              )}
              <Icon
                icon={`${isDarkMode ? "sun" : "moon"}`}
                className={`block md:block border-none nav-button ${isDarkMode ? "text-white bg-custom-dark-200 hover:bg-custom-dark-300" : "text-black bg-slate-300 hover:bg-slate-400"}`}
                onClick={toggleDarkMode}
                style={{ border: "none" }}
              />
              <Icon
                icon="bars"
                className={`nav-button ${isDarkMode ? "text-white hover:bg-custom-dark-300" : "text-black hover:bg-slate-300"}`}
                onClick={toggleNav}
              />
            </div>
            <div className={`logo ${isDarkMode ? "text-white" : "text-black"}`}>
              <img
                src={isDarkMode ? logoLight : logoDark}
                alt="Logo"
                className="w-[150px] h-[calc(100%-30px)] cursor-pointer"
                onClick={() => navigate("/")}
              />
            </div>
            <div className="nav-menu">
              <div className={` ${isVisible ? "block" : "hidden"} `}>
                <ul>
                  {menuItems.map((item, index) => (
                    <li
                      key={index}
                      className={`hover:bg-transparent ${isDarkMode ? "md:hover:bg-custom-dark-300 text-white" : "md:hover:bg-white"}`}
                    >
                      <Link to={item.url}>
                        {item.text} {item.submenu && <Icon icon="angle-down" />}
                      </Link>
                      {item.submenu && (
                        <ul
                          className={`submenu relative ${isDarkMode ? "bg-custom-dark-300" : "bg-white"}`}
                          style={{ zIndex: 1000 }}
                        >
                          {item.submenu.map((subItem, subIndex) => (
                            <li key={subIndex}>
                              <Link to={subItem.url}>{subItem.text}</Link>
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </Container>
      </nav>

      {!noOutlet && <Outlet />}

      {!noFooter && (
        <footer
          className={`${isDarkMode ? "bg-[#333]" : "bg-[#ddd]"}`}
          style={{}}
        >
          <Container style={{ overflow: "hidden" }}>
            <div className="p-8">
              <div className="flex w-full flex-col md:flex-row flex-wrap">
                <div className="flex-1 w-full">
                  <h2 className="text-xl font-light mb-2 mt-5">Quick Links</h2>
                  <ul>
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/plans/">Pricing</Link>
                    </li>
                    <li>
                      <Link to="/blog/">Blog</Link>
                    </li>
                    <li>
                      <Link to="/contact/">Contact</Link>
                    </li>
                  </ul>
                </div>

                <div className="flex-1">
                  <h2 className="text-xl font-light mb-2 mt-5">Products</h2>
                  <ul className="m-0 p-0">
                    <li>
                      <Link to="/blog/capify/">Capify AI</Link>
                    </li>
                    <li>
                      <Link to="/blog/voice-ai/">Voice AI</Link>
                    </li>
                  </ul>
                </div>

                <div className="flex-1">
                  <ul className="m-0 lg:mt-14 p-0">
                    <li>
                      <Link to="/disclaimer/">Disclaimer</Link>
                    </li>
                    <li>
                      <Link to="/terms-of-use/">Terms of Use</Link>
                    </li>
                    <li>
                      <Link to="/privacy-policy/">Privacy Policy</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </Container>
          <div
            className={`flex justify-center items-center p-8 ${isDarkMode ? "bg-[#444]" : "bg-[#eee]"}`}
          >
            <p className="text-sm text-center">
              &copy; {new Date().getFullYear()} <strong>Capify</strong>, a
              product of{" "}
              <Link
                to="https://www.gci.ng/"
                target="_blank"
                className="text-blue-400 hover:underline"
              >
                GCI
              </Link>
              . All rights reserved.
            </p>
          </div>
        </footer>
      )}
    </div>
  );
};

export default Navbar;
