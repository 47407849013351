import React, { useEffect, Suspense, lazy } from "react";
import { ThemeProvider } from "./utils/ThemeContext";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import Navbar from "./utils/custom-components/NavBar";
import ProgressBar from "./utils/custom-components/ProgressBar";
import { AuthProvider } from "./utils/AuthContexts";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { CircularProgress } from "@mui/material";
import GoogleAnalytics from "./utils/custom-components/GoogleAnalytics";
import ThemeModal from "./utils/custom-components/showThemeModal";
import ShowSocialMediaModal from "./utils/custom-components/ShowSocialMediaModal";

// Lazy load components
const Root = lazy(() => import("./components/Root"));
const Login = lazy(() => import("./components/account/Login"));
const Register = lazy(() => import("./components/account/Register"));
const Account = lazy(() => import("./components/account/Account"));
const Profile = lazy(() => import("./components/account/Profile"));
const ForgotPw = lazy(() => import("./components/account/forgot_pw"));
const ChangePw = lazy(() => import("./components/account/Change_Password"));
const Plan = lazy(() => import("./components/account/Plan"));
const Dashboard = lazy(() => import("./components/Dashboard"));
const BusinessGrowth = lazy(
  () => import("./components/business-console/BusinessGrowth")
);

const BusinessConsole = lazy(
  () => import("./components/business-console/BusinessConsole")
);

const AddBusiness = lazy(
  () => import("./components/business-console/AddBusiness")
);
const AddRecord = lazy(() => import("./components/business-console/AddRecord"));
const GenerateIdea = lazy(() => import("./components/Generate"));
const IdeaResult = lazy(() => import("./components/Result"));
const BusinessPlan = lazy(() => import("./components/ai/PlanScreen"));
const AdvisorAI = lazy(() => import("./components/ai/AdvisorAI"));
const ProtectedRoute = lazy(() => import("./utils/ProtectedRoutes"));
const SavedPlansPreview = lazy(() => import("./components/SavedPlansPreview"));
const IdeaDetails = lazy(() => import("./components/IdeaDetails"));
const AllHistory = lazy(() => import("./components/IdeaHistory"));

//
const BlogHome = lazy(() => import("./components/blog/Home"));
const NewPost = lazy(() => import("./components/blog/New"));
const ReadBlogPost = lazy(() => import("./components/blog/read"));

//
const Contact = lazy(() => import("./components/Contact"));
const Faq = lazy(() => import("./components/Faq"));
const Disclaimer = lazy(() => import("./components/Disclaimer"));
const Privacy = lazy(() => import("./components/Privacy"));
const Terms = lazy(() => import("./components/Terms"));

//
const LogoDesiner = lazy(() => import("./components/designer/LogoDesigner"));

// ScrollToTop component
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "auto" });
    }, 700);
  }, [pathname]);

  return null;
};

// App component
const App = () => {
  const location = useLocation();

  return (
    <AuthProvider>
      <ThemeProvider>
        <ScrollToTop />
        <ProgressBar />
        <GoogleAnalytics />
        <TransitionGroup>
          <CSSTransition key={location.key} classNames="fade" timeout={500}>
            <Suspense
              fallback={
                <div className="relative h-screen">
                  <div className="sticky top-0">
                    <Navbar noFooter noOutlet />
                  </div>
                  <div className="w-full h-full flex flex-col gap-3 justify-center items-center">
                    <CircularProgress
                      size={"30px"}
                      className="text-custom-blue"
                    />{" "}
                    Loading...
                  </div>
                </div>
              }
            >
              <Routes>
                <Route path="/" element={<Navbar />}>
                  <Route index element={<Root />} />
                  <Route path="/contact/" element={<Contact />} />
                  <Route path="/faq/" element={<Faq />} />
                  <Route path="/disclaimer/" element={<Disclaimer />} />
                  <Route path="/privacy-policy/" element={<Privacy />} />
                  <Route path="/terms-of-use/" element={<Terms />} />
                  <Route path="/account/login/" element={<Login />} />
                  <Route path="/account/register/" element={<Register />} />
                  <Route path="/blog/" element={<BlogHome />} />
                  <Route path="/blog/read/:id/" element={<ReadBlogPost />} />
                  <Route
                    path="/admin/blog/:action/:postid?/"
                    element={<ProtectedRoute element={<NewPost />} />}
                  />
                  <Route
                    path="/account/forgot-password/"
                    element={<ForgotPw />}
                  />
                  <Route path="/plans/" element={<Plan />} />
                  <Route
                    path="/account/me/"
                    element={<ProtectedRoute element={<Account />} />}
                  />
                  <Route
                    path="/account/profile/"
                    element={<ProtectedRoute element={<Profile />} />}
                  />
                  <Route
                    path="/account/change-password/"
                    element={<ProtectedRoute element={<ChangePw />} />}
                  />
                  <Route
                    path="/account/dashboard"
                    element={<ProtectedRoute element={<Dashboard />} />}
                  />
                  <Route
                    path="/account/dashboard/generate"
                    element={<ProtectedRoute element={<GenerateIdea />} />}
                  />
                  <Route
                    path="/account/dashboard/generate/result/"
                    element={<ProtectedRoute element={<IdeaResult />} />}
                  />
                  <Route
                    path="/account/dashboard/generate/plan/"
                    element={<ProtectedRoute element={<BusinessPlan />} />}
                  />
                  <Route
                    path="/account/dashboard/idea/history/"
                    element={<ProtectedRoute element={<AllHistory />} />}
                  />
                  <Route
                    path="/account/dashboard/idea/details/:id/"
                    element={<ProtectedRoute element={<IdeaDetails />} />}
                  />
                  <Route
                    path="/account/dashboard/generate/plan/saved-plan"
                    element={<ProtectedRoute element={<SavedPlansPreview />} />}
                  />

                  <Route
                    path="/account/dashboard/designers/:logo/"
                    element={<ProtectedRoute element={<LogoDesiner />} />}
                  />
                  <Route
                    path="/account/dashboard/business-console/:bid/"
                    element={<ProtectedRoute element={<BusinessGrowth />} />}
                  />
                  <Route
                    path="/account/dashboard/business-console/:bid/add-record/"
                    element={<ProtectedRoute element={<AddRecord />} />}
                  />
                  <Route
                    path="/account/dashboard/business-console/add-business/"
                    element={<ProtectedRoute element={<AddBusiness />} />}
                  />

                  <Route
                    path="/account/dashboard/business-console/"
                    element={<ProtectedRoute element={<BusinessConsole />} />}
                  />

                  <Route
                    path="*"
                    element={
                      <div className="h-96 flex justify-center items-center flex-col ">
                        <h1 className="text-4xl lg:text-5xl text-gray-400">
                          404
                        </h1>
                        <p className="text-gray-500 text-lg">Page not found</p>
                      </div>
                    }
                  />
                </Route>
                <Route
                  path="/account/dashboard/generate/advisor/"
                  element={<ProtectedRoute element={<AdvisorAI />} />}
                />
              </Routes>
              <ThemeModal />
              <ShowSocialMediaModal />
            </Suspense>
          </CSSTransition>
        </TransitionGroup>
      </ThemeProvider>
    </AuthProvider>
  );
};

// Main render wrapped with Router
const MainApp = () => (
  <Router>
    <App />
  </Router>
);

export default MainApp;
