import React, { createContext, useState, useEffect } from "react";
import { endpoint } from "./Constants";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [status, setStatus] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [presets, setPresets] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [country, setCountry] = useState(false);

  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    return !!localStorage.getItem("user");
  });

  useEffect(() => {
    const preloadPresets = async () => {
      await loadPresets();
    };
    preloadPresets();
  }, []);

  useEffect(() => {
    const checkUserLoggedIn = async () => {
      const checkUser = localStorage.getItem("user");
      if (checkUser) {
        setUserLoggedIn(true);
        setUserInfo(JSON.parse(checkUser));
      }
    };
    checkUserLoggedIn();
  }, []);

  useEffect(() => {
    const preloadGeo = async () => {
      const geo = await fetchGeolocation();
      if (geo) {
        setCountry(true);
      } else {
        setCountry(false);
      }
    };
    preloadGeo();
  }, []);

  async function fetchData(endpoint, method = "POST", postData) {
    try {
      const apiUrl = endpoint;
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), 30000); // Timeout after 30 seconds
      // Prepare fetch options
      const options = {
        method: method,
        signal: controller.signal,
      };
      // If it's a POST request, include the data
      if (method === "POST") {
        options.headers = {
          "Content-Type": "application/json",
        };
        options.body = JSON.stringify(postData);
      }
      const response = await fetch(apiUrl, options);
      clearTimeout(timeoutId); // Clear timeout if request succeeds within 30 seconds

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const res = await response.json();

      if (!res) {
        throw new Error("Invalid response from server");
      }
      return res;
    } catch (error) {
      if (error.name === "AbortError") {
        console.error("Request timed out");
        // Handle timeout error
        alert("Request timed out. Please try again later.");
      } else {
        console.error("Error:", error.message);
        // Handle other errors
        alert("An error occurred while fetching data. Please try again later.");
      }
    }
  }

  async function fetchGeolocation() {
    try {
      const response = await fetch(`${endpoint}/geolocation/`);
      const data = await response.json();
      if (data.country === "NG") {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error("Error fetching geolocation:", error);
    }
  }

  const login = async (email, password) => {
    setLoading(true);
    const params = { email: email, password: password };

    try {
      const res = await fetchData(endpoint + "/login/", "POST", params);
      const status = res?.status;
      if (status === false) {
        return res;
      } else {
        //set error to false
        setStatus(true);
        setError(null);

        setUserLoggedIn(true);
        //hide loading
        setLoading(false);
        //cache user data

        localStorage.setItem("user", JSON.stringify(res.message));
        setIsAuthenticated(true);

        setUserInfo(res.message);
        return res;
      }
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const register = async (email, password, phone, name) => {
    setLoading(true);
    const params = {
      email: email,
      password: password,
      phone: phone,
      name: name,
      location: "Nigeria",
    };

    try {
      const res = await fetchData(endpoint + "/register/", "POST", params);

      if (res.status === false) {
        setStatus(false);
        setError(res.message);
        setLoading(false);
      } else {
        return true;
      }
    } catch (error) {
      setError(error);
      setLoading(error);
      return false;
    }
  };

  const resetPassword = async (email) => {
    const params = { email: email };

    try {
      const res = await fetchData(
        endpoint + "/password/reset/",
        "POST",
        params
      );
      const status = res?.status;
      if (status === false) {
        return {
          status: false,
          message: res?.message,
        };
      } else {
        //set error to false
        return {
          status: true,
          message: res?.message,
        };
      }
    } catch (error) {
      return {
        status: false,
        message: error,
      };
    }
  };

  const logout = async () => {
    try {
      localStorage.removeItem("user");
      setUserInfo(null);
      setUserLoggedIn(false);
      setIsAuthenticated(false); // Update isAuthenticated state
    } catch (error) {
      console.error("Logout error:", error);
      setStatus(false);
    }
  };

  const loadPresets = async () => {
    const params = {};

    try {
      const res = await fetchData(endpoint + "/presets/", "POST", params);
      const status = res?.status;
      if (status !== false) {
        //cache user data
        setPresets(res?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        loading,
        error,
        login,
        register,
        logout,
        resetPassword,
        isAuthenticated,
        status,
        userInfo,
        setUserInfo,
        setError,
        userLoggedIn,
        fetchData,
        presets,
        setRememberMe,
        rememberMe,
        country,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => React.useContext(AuthContext);
